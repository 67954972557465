@import "normalize";
@import "typography";
@import "colors";
@import "overrides";


* {
  box-sizing: border-box;
}

body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $gray-dark;
}

div, span, p {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  letter-spacing: 1px;
  color: $gray-dark;
}
