$white: #FFF;
$black: rgba(15, 2, 14, 1);
$gray: rgba(63, 67, 65, 1);
$gray-dark: rgba(63, 67, 65, 1);
$gray-light: rgba(254, 254, 253, 1);
$teal: rgba(103, 141, 151, 1);
$yellow: rgba(255, 250, 170, 1);

$brand: $teal;
$brand-dark: #2c3c44;
