html, body{
  height: 100%;
}

body{
  position: absolute;
  width:100%;
  overflow-y:scroll;
}

main {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display:block;
  // padding-top: 94px;
  background: $white;
}

section.white {
  background: $white;
}
section.gray {
  background: $gray-light;
}

.container {
  display:flex;
  flex-direction: row;
  align-self: center;
  margin: 0 auto;
  padding: 0 24px;
  @include mq($from: sm){
    padding: 0 24px;
  }
  @include mq($from: md) {
    max-width: 1000px;
    padding: 0 24px;
  }
  @include mq($from: lg) {
    max-width: 1200px;
    padding: 0 32px;
  }
}
