.hero, .masthead{
  display:flex;
  flex-direction: column;
  justify-content: center;
  background:
       linear-gradient(45deg,
        rgba($teal, 1), 
        rgba(#3c4c55, .9)
      ),
      url('/assets/img/patterns/rockywall.png');
  position: relative;

  & > .container{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    color: $white;
    text-shadow: -1px 1px 3px rgba($gray-dark, .64), -1px 0px 15px rgba($black, .08);
  }
  & .container > div {
      color: $white;
      text-shadow: -1px 1px 3px rgba($gray-dark, .64), -1px 0px 15px rgba($black, .08);
  }
  &__headline{
    @include h1;
    padding-bottom: 36px;
    margin-bottom: 16px;
    position: relative;
    z-index: 5;
    &:after{
      content: ' ';
      position: absolute;
      width: 176px;
      height: 2px;
      background: $teal;
      bottom: 0;
      left: 4px;
    }
    &:before{
      content: ' ';
      position: absolute;
      width: 176px;
      height: 6px;
      background: $white;
      bottom: 0;
      left: 4px;
    }
  }
  &__desc{
    @include p1;
    z-index:5;
  }
}

.hero--bg, .masthead--bg{
  position: relative;
  z-index: 3;
  .bg__filter{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    filter: sepia(80%);
    opacity: .3;
    z-index: 4;
  }
}

.hero {
  // padding-top: 60px;
  height: 472px;
  @include mq($from: md){
    // padding-top: 94px;
    height: 90vh;
    // height: 496px;
  }
}

.masthead {
  height: 256px;
  padding-top: 56px;
  @include mq($from:sm){
    height: 320px;
    padding-top: 94px;
  }
}