// Base Fonts
@import url('https://fonts.googleapis.com/css?family=Futura:300,400,900,100|Roboto:400,300');

// Font Weights
$light: 100;
$regular: 300;
$bold: 400;
$bolder: 700;

// Base Font
$base-font-family: 'Roboto', sans-serif;
$base-font-weight: 300;
$base-font-size: 16px;
$base-line-height: 1.8rem;

// Headings
$heading-font-family: 'Futura', sans-serif;
$heading-font-weight: $regular;

// Header
$nav-font-size: 12px;
$nav-heading-font-size: 24px;
$nav-heading-font-weight: $regular;
$nav-heading-font-family: 'Muli', sans-serif;

// menu
@mixin menu-text{
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  font-size: 40px;
}

// titles
@mixin h1{
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  font-size: 28px;
  line-height: 38px;
  @include mq($from: sm){
    font-size: 48px;
    line-height: 54px;
  }
  @include mq($from: md){
    font-size: 52px;
  }
  @include mq($from: lg){
    font-size: 60px;
    line-height: 60px;
  }
}
@mixin h2 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  margin: 0;
  font-size: 36px;
  line-height: 40px;
  @include mq($from: md){
    font-size: 44px;
    line-height: 48px;
  }
  @include mq($from: xl){
    font-size: 48px;
    line-height: 64px;
  }
}
@mixin h3 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  margin: 0;
  font-size: 28px;
  line-height: 36px;
  padding-bottom:24px;
  @include mq($from: md){
    font-size: 34px;
    line-height: 42px;
  }
  @include mq($from: xl){
    font-size: 34px;
    line-height: 48px;
  }
}
// sub-titles
@mixin p1{
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: 20px;
  line-height: 24px;
  @include mq($from: sm){
    font-size: 24px;
    line-height: 28px;
  }
  @include mq($from: md){
    font-size: 28px;
    line-height: 40px;
  }
  @include mq($from: lg){
    font-size: 32px;
    line-height: 48px;
  }
}
@mixin p2{
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  @include mq($from: sm){
    font-size: 24px;
    line-height: 34px;
  }
  @include mq($from: md){
    font-size: 28px;
    line-height: 40px;
  }
  @include mq($from: lg){
    font-size: 30px;
    line-height: 48px;
  }
}
@mixin p3{
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  @include mq($from: sm){
    font-size: 24px;
    line-height: 34px;
  }
  @include mq($from: md){
    font-size: 28px;
    line-height: 40px;
  }
  @include mq($from: lg){
    font-size: 28px;
    line-height: 48px;
  }
}
@mixin p4{
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: 16px;
  margin: 0;
}


