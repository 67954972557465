.footer{
  $footer-accent-color: $white;
  background: linear-gradient(
    0deg,
    rgba($brand-dark, 1),
    rgba($brand-dark, 1)
  );
  display: flex;
  width: 100%;
  padding: 48px 0;
  .footer__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer__container > ul, .footer__container > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer__logo{
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: solid 2px $footer-accent-color;
  }
  .footer__logo svg{
    height: 40px;
    .cls-1{
      fill: $footer-accent-color;
    }
    @include mq($from: sm){
      height: 72px;
      .cls-1{
        fill: $footer-accent-color;
      }
    }
  }
  ul.footer__social{
    list-style: none;
    margin: 0;
    padding: 0;
    padding-bottom: 24px;
  }
  .footer__social{
    width: 100%;
    display:flex;
    justify-content: center;
    padding-bottom: 16px;
    ul{
      margin: 0;
      padding: 0 0;
    }
    li{
      display:inline-block;
      padding: 0 20px;
      a{
        display:inline-block;
        background-image:url('/assets/img/global/social.png');
        background-size: 268px 72px;
        margin: 0;
        line-height: 1;
        width: 32px;

        &.instagram{
          height: 32px;
          background-position: -20px;
        }	
        &.facebook{
          height: 32px;
          background-position: -72px;
        }
        &.linkedin{
          height: 32px;
          background-position: -125px;
        }
        &.twitter{
          height: 32px;
          background-position: -176px;
        }
        &.tumblr{
          height: 32px;
          background-position: -228px;
        }
      }
      
    }
  }
  .footer__copyright{
    color: $footer-accent-color;
    padding-top: 16px;
    a{
      color: inherit;
      text-decoration: none;
      padding-left: 4px;
    }
  }
}