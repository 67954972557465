.header{
  position: fixed;
  top: 0;
  height: 94px;
  min-width: 100%;
  z-index: 999;
  background: transparent;
  font-weight: 400;
  display:flex;
  flex-direction: column;
  justify-content: center;

  &.filled{
    $this-bg-color:#000;
    $this-bg-color:#3e434b;
    $this-bg-color:#2c3c44;
    $this-bg-color: $brand-dark;
    $this-alpha: 1;
    transition: background-color ease-out .1s;
    background: darken($this-bg-color,.6);
    // background:
    //   linear-gradient(-45deg,
    //     rgba(darken($this-bg-color, .8), $this-alpha),
    //     rgba(darken($this-bg-color,.4), $this-alpha)
    //   );
      // url('/assets/img/patterns/greyzz_@2X.png');    
    // background: linear-gradient(),url('/assets/img/patterns/p6_@2X.png');
    // background: url('/assets/img/patterns/greyzz_@2X.png');
    // background: url('/assets/img/patterns/rockywall.png');
    // opacity: .3;

    box-shadow: 0 0px 16px rgba(0,0,0,0.091), 0 3px 17px rgba(0,0,0,0.06);
    transition: background ease-out .3s;

    $this-logo-color: $white;
    // $this-logo-color: $teal;
    .header__logo{
      // padding-top: 0;
      @include mq ($from: sm){
        padding-top: 8px;
      }
    }
    .header__logo svg{
      height: 40px;
      transition: height ease-out .15s;
      .cls-1{
        fill: $this-logo-color;
      }
      @include mq($from: sm){
        height: 72px;
        .cls-1{
          fill: $this-logo-color;
        }
      }
    }
    .nav__icon{
      span, span:after, span:before {
        background: $this-logo-color;
      }
    }
  }

  &__container{
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    // position: relative;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    $header-padding-top: 4px;
    @include mq($from: xs){
      padding: $header-padding-top 16px;
    }
    @include mq($from: md) {
      max-width: 1200px;
      padding: $header-padding-top 16px;
    }
    @include mq($from: lg){
      max-width:1440px;
    }

  }

  &__logo {
    flex: 1 0 auto;
    // position: absolute;
    left: 0;
    
    padding-top: 20px;
    @include mq ($from: sm){
      padding-top: 16px;
    }
    @include mq($from: md){
      padding-left: 24px;
      padding-top: 16px;
    }
    svg{
      height: 36px;
      .cls-1{
        fill: $white;
      }
      @include mq($from: sm){
        height: 72px;
        .cls-1{
          fill: $white;
        }
      }
      @include mq($from: md){
        height: 94px;
      }
    }
  }

  &__nav {
    .nav__icon{
      margin-top:7px;
      position: absolute;
      top: 0;
      right: 0;
      height: 72px;
      width: 72px;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      color: transparent;
      z-index:200;
      span, span:after, span:before {
        position: absolute;
        background: linear-gradient(24deg, $white 0%, $white 100%);
        height: 3px;
        width: 26px;
      }
      span {
        @include center;
        // transition: background-color .1s;
        &:after, &:before{
          content: '';
          left: 0;
          transition: transform .15s linear;
        }
        &:before {
          transform: translateY(-9px);
        }
        &:after{
          transform: translateY(9px);
        }
      }
    }
    .main__nav{
      opacity: 0;
      visibility: hidden;
      z-index:-99;
    }
  }
}

.main__nav{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  background: transparent;
  color: transparent;
  transition: opacity .3s ease-out, color .6s linear;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  @include mq($until: md){
    height: 100vh;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li{
    width: 100%;
    text-align: center;
    padding: 16px 0;
    @include menu-text;
    a{
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      &:hover{
        color: darken($white, 15);
      }
    }
  }
  
}

.header__nav.nav-open{
  .main__nav{
    opacity: 1;
    visibility: visible;
    z-index:99;
    background: $brand-dark;
    color: $white;
  }
  .nav__icon span{
    background-color: transparent;
    background: none;
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}