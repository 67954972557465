.internal-page{
  section.service{
    position: relative;
    width: 100%;
    background: $gray-light;
    padding: 40px 0 0;
    @include mq($from: md){
      padding: 56px 0 0; 
    }
    @include mq($from: lg){
      padding: 80px 0 0; 
    }
  }
  
  .container{
    flex-direction: column;
  }

  .service__lead, h2{
    @include h2;
    padding-bottom: 24px;
  }
  h3{
    padding-top: 24px;
    @include h3;
  }
  p{
    @include p3;
    padding-bottom: 24px;
  }
  ul{
    margin: 0;
    // padding: 24px 0;
    columns: 1;
    list-style: none;
    @include mq($until: md){
       padding-left:0;
    }
  }
  li{
    @include p3;
    position:relative;
    padding-left: 48px;
    padding-bottom: 8px;
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    // line-height: 28px;
    @include mq($from: sm){
      display: block; 
    }
    @include mq($from: lg){
      padding-bottom: 16px;
    }
    &:before{
      // content: " ";
      // // background: url(/assets/img/global/logo--only.svg);
      // // background-size: 36px;
      // // background-repeat: no-repeat;
      // // background-size: contain;
      // display: block;
      // height: 30px;
      // width: 100%;
      // padding-top: 4px;
      // min-height: 24px;
      // position: absolute;
      // top: 0;
      // left:4px;

      content: "-";
      display: block;
      min-height: 24px;
      position: absolute;
      top: 0px;
      left: 12px;

      @include mq($from:md){
        // top:2px;
        top: -2px;
      }
      @include mq($from:lg){
        // top:4px;
        top: -2px;
      }
    }
    &:last-child{
      padding-bottom: 40px;
    }
  }
  .breadcrumb{
    color: lighten($gray-dark, 10);
    @include p4;
    padding-bottom: 40px;
    a{
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      text-decoration: none;
      color: inherit;
    }
  }
  .row{
    position: relative;
    z-index: 1;
    padding: 48px 0 48px;
    &:before{
      content: "";
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%; 
      height: 100%;  
      opacity: .08; 
      z-index: -1;
      background: url(/assets/img/global/logo--only.svg);
      background-attachment: fixed;
      background-size: 144px;
      background-position: right bottom;
      background-repeat: no-repeat;
    }
    &:first-child{
      padding-top: 0;
    }
    // &:last-child{
    //   padding-bottom: 40px;
    //   @include mq($from: md){
    //     padding-bottom: 56px; 
    //   }
    //   @include mq($from: lg){
    //     padding-bottom: 80px; 
    //   }
    // }
  }

  .service__list{
    padding-bottom: 40px !important;
    @include mq($from: md){
      padding-bottom: 56px !important;
    }
    @include mq($from: lg){
      padding-bottom: 80px !important;
    }
  }

  .service__row{
    padding: 16px 0;
  }

  .call-out{
    padding: 40px 0;
    background: $teal;
    *{
      color: $white !important;  
    }
    &:before{
      opacity: 0;
    }
    li:before {
      // background: url(/assets/img/global/logo--only+inverse.svg);
      // background-size: 36px;
      // background-repeat: no-repeat;
      // background-size: contain;
    }
    li:last-child{
      padding-bottom: 32px;
      @include mq($from:lg){
        padding-bottom: 48px;
      }
    }
    h3{
      position: relative;
      margin-bottom: 32px;
    }
    h3:after{
      content: '';
      position: absolute;
      z-index: 1;
      height: 2px;
      bottom: -3px;
      left: 0;
      width: 25%;
      background-color: $white;
    }
  }

  .deliverable{
    .service__lead{
      padding-top: 24px;
    }
  }

  .convert{
    a{
      text-decoration: none;
      transition: all ease-in-out 1s;
      &:hover{
        text-decoration: underline;
        transition: all ease-in-out 1s;
      }
    }
  }
}