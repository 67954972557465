// Button Styles

button, .btn{
  color: $white !important;
  background: $teal;
  padding: 15px 20px 10px;
  width: 250px;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  border: 5px solid $teal;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  transition: border .5s;
  text-align: center;
  &:hover{
    border: 5px solid darken($teal, 5);
  }
}
