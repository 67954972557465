a.link{
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline-block;
  transition: color ease 0.3s;
  &:before, &:after{
    content:'';
    position: absolute;
    z-index: 1;
    height: 2px;
    bottom: 2px;
    left: 0;
  }
  &:before{
    width: 100%;
    transition: color ease 0.1s;
    background-color: $gray-dark;
  }
  &:after{
    width: 100%;
    background-color: $teal;
    transition: all ease 0.3s;
  }
  &:hover {
    &::before {
      width: 100%;
    }
    &::after {
      left: 100%;
      width: 0%;
      transition: all ease 0.2s;
    }
  }
}
.home-page{
  .home__hero{
    background:
       linear-gradient(
        rgba(255, 0, 0, 0.45), 
        rgba(255, 0, 0, 0.45)
      ),
      url('/assets/img/patterns/rockywall.png');
    // border-bottom: solid 4px $teal;
    position: relative;

    .container > div {
      color: $white;
    }

    &.hero--bg{
      background:
        linear-gradient(45deg,
          rgba(darken($teal, .2), .575),
          rgba(0,0,0,0)
        ),
        linear-gradient(-45deg,
          rgba(lighten($teal, .2), .05),
          rgba(darken($teal,.2), .45)
        ),
      url('/assets/img/home/hero-bg.jpg') center center;
      background-size: cover;
      @include mq($from: md){
        background:
        linear-gradient(45deg,
          rgba(darken($teal, .2), .575),
          rgba(0,0,0,0)
        ),
        linear-gradient(-45deg,
          rgba(lighten($teal, .2), .05),
          rgba(darken($teal,.2), .45)
        ),
        url('/assets/img/home/hero-bg.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  .home__intro{
    position: relative;
    width: 100%;
    background: $gray-light;
    // box-shadow: 0 -7px 20px rgba(0,0,0,0.05), 0 -5px 18px rgba(0,0,0,0.03);
    padding-top: 40px;
    @include mq($from: md){
      padding-top: 56px; 
    }
    @include mq($from: lg){
      padding-top: 80px; 
    }
    .intro__title{
      @include h2;
    }
    .intro__desc{
      @include p2;
      padding-top: 24px;
    }
    ul.intro__items{
      margin: 0;
      padding: 24px 0;
      columns: 1;
      list-style: none;
      @include mq($from: md){
        columns: 2;
        padding: 24px 40px;
      }
    }
    li.intro__item{
      @include p2;
      position:relative;
      padding-left: 48px;
      padding-bottom: 16px;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      line-height: 28px;
      @include mq($from: sm){
        display: block; 
      }
      @include mq($from: lg){
        padding-bottom: 24px;
      }
      &:before{
        content: "-";
        // background: url(/assets/img/global/logo--only.svg);
        background-size: 36px;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 30px;
        width: 100%;
        padding-top: 0px;
        min-height: 24px;
        position: absolute;
        top: -4px;
        left:4px;
      }
    }
  }
  .intro__right-border{
    $intro__right-border__offset: 108;
    position: absolute;
    width: calc((100vw - 1200px)/2);
    max-width: 208px;
    height: calc(100% + 1px * #{$intro__right-border__offset});
    min-height: calc(500px + 1px * (#{$intro__right-border__offset}));
    background: $teal;
    top: calc(-1px * #{$intro__right-border__offset});
    right: 0;
    z-index: 50;
  }
  .intro__container{
    width: 100%;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 60px;
    position: relative;
    @include mq($from: md){
      padding-bottom: 24px;
    }
    @include mq($from: lg){
      padding-bottom: 36px;
    }

    // &:after{
    //   content: " ";
    //   position: absolute;
    //   z-index: 51;
    //   height: 100%;
    //   width: 11px;
    //   top: 0;
    //   right: 0;
    //   background: $white;
    //   box-shadow: 14px -69px 20px rgba(0, 0, 0, 0.10);
    // }
  }

  .home__services{
    display: block;
    position: relative;
    width: 100%;
    background: #e0e8ea;
    padding: 40px 0;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: nowrap;
    .services__container{
      width: 100%;
      flex-direction: column;
      padding-bottom: 24px;
    }
    .services__img{
      display: none;
      position: absolute;
      top: -48px;
      right: 0;
      border-left: solid 4px $teal;
      border-top: solid 4px $teal;
      border-bottom: solid 4px $teal;
      z-index: 100;
      @include mq($from: 950px){
        display: block;
      }
      img{
        max-width: 400px;
      }
      .img-filter{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        // opacity: .7;
        z-index: 101;
        top: 0;
        left: 0;
        background:
          linear-gradient(-45deg,
            rgba(lighten($black, .2), .15),
            rgba(darken($teal,.2), .80)
          ),
          linear-gradient(
            rgba($black, 0.45), 
            rgba($black, 0.45)
          );
          opacity: .3;
          // background:
          // linear-gradient(-45deg,
          // rgba(darken($teal,.3), 1),
          // rgba(lighten($black, .2), .15)
          // ),
          // linear-gradient(
          //   rgba($black, 0.45), 
          //   rgba($black, 0.45)
          // );
        // filter: blur(2px);
      }
    }
    
    .services__title{
      @include h2;
      padding-top: 24px;
    }
    ol.services__items{
      margin: 0;
      padding: 24px 40px 0;
    }
    li.services__item{
      @include p2;
      padding-bottom: 16px;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      @include mq($from: 949px){
        max-width: 400px;
      }
      @include mq($from: lg){
        padding-bottom: 24px;
        max-width: 550px;
      }
      @include mq($from: xl){
        max-width: 700px;
      }
      & > span{
        margin-left: 12px;
      }
    }
    .services__desc{
      @include p2;
      padding: 24px 0;
      max-width: 544px;
      @include mq($from: sm){
        padding:16px 0 40px;
      }
      @include mq($from:lg){
        padding-top: 24px;
      }
      & > span {
        display: inline-block;
        padding-top: 16px;
        line-height: 42px;
        .link:before, .link:after{
          bottom: -2px;
        }
      }
    }
  }

  .home__break{
    width: 100%;
    background: $teal;
    position: relative;
    padding: 16px 0;
    @include mq($from: 950px){
      padding: 48px 0;
    }
    .break__container{
      text-align: center;
      margin: 0 auto;
      justify-content: center;
    }
    .break__text{
      @include h2;
      color: $white;
    }
  }

  .home__clients{
    display: block;
    position: relative;
    width: 100%;
    background: $gray-light; 
    .clients__container{
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
      padding-top: 40px;
      @include mq($from: md){
        padding-top: 56px; 
      }
      @include mq($from: lg){
        padding-top: 80px; 
      }
    }
    .clients__title{
      @include h2;
      text-align: center;
      padding-bottom: 16px;
      @include mq($from: 950px){
        text-align: left;
      }
    }
    ul.clients__items{
      margin: 0;
      padding: 24px 40px;
      columns: 1;
      list-style: none;
      @include mq($from: sm){
        columns: 2;
      }
      @include mq($from: md){
        columns: 3;
      }
    }
    li.clients__item{
      @include p1;
      color: $teal;
      text-align: center;
      padding-bottom: 16px;
      margin: 0 !important;
    }

    .clients__testimonials{
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
      position: relative;
      padding-top: 40px;
      @include mq($from: md){
        padding-top: 56px; 
      }
      @include mq($from: lg){
        padding-top: 80px; 
      }

      .container{
        display: block;
        position: relative;
        width: 100%;
        
      }
    }
    .testimonial__block{
      position: absolute;
      top: 0;
      left: -24px;
      height: 102px;
      width: 6px;
      background: $teal;
    }
    .testimonial__quote{
      @include p2;
      position: relative;
      display:none;
      opacity: 0;
      visibility: hidden;
      transition: all ease-in-out .3s;
      padding-left: 16px;
      min-height: 240px;
      @include mq($from: 400px){
        min-height: 220px;
      }
      @include mq($from: 500px){
        min-height: 200px;
      }
      @include mq($from:md){
        padding-left: 48px;
      }
      @include mq($from:lg){
        min-height: 215px;
      }
      &.quote--shown{
        width: 100%;
        display: block;
        opacity: 1;
        visibility: visible;
        transition: all ease-in-out .6s;
      }
      p{
        margin: 0 0 16px;
      } 
      span{
        padding-top: 4px;
      }
      
    }
    .testimonial__link{
      @include p2;
      padding-top: 16px;
      padding-left: 16px;
      @include mq($from:md){
        padding-top: 48px;
        padding-left: 48px;
      }
    }

  }

  .home__contact{
    display: block;
    position: relative;
    width: 100%;
    background: $gray-light;  
    padding-top: 40px;
    @include mq($from: md){
      padding-top: 56px; 
    }
    @include mq($from: lg){
      padding-top: 80px; 
    }
    .contact__container{
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap:wrap;
      min-height: 510px;
      width: 100%;
      // padding-top: 40px;
      padding-bottom: 80px;
      @include mq($from: lg){
        flex-wrap: nowrap;
        padding-top: 80px; 
        max-width: 1664px;
      }
    }
    .contact__img{
      img{
        width: 100%;
      }
      @include mq($until: lg){
        width: 100%;
        padding-bottom: 24px;
      }
      @include mq($from: lg){
        transform: translateX(-100px);
      }
    }
    .contact__area{
      position: relative;
      flex: 1 0 auto;
      padding-top: 32px;
      padding-left: 24px;
      padding-right: 24px;
      @include mq($until: md){
        width: 100%;
      }
      @include mq($from: sm, $until: lg){
        padding-left: 40px;
        padding-right: 40px;
      }
      form{
        padding-top: 24px;
        max-width: 424px;
        button{
          @include mq($until: md){
            width: 100%;
          }
        }
      }
    }
    .contact__title{
      @include h2;
    }
    .contact__schedule{
      padding: 16px 0 24px;
      @include p3;
    }
    .contact__border{
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(calc(100vw - 20%));
      width: 100vw;
      height: 16px;
      background: $teal;
    }
  }

}