.no-margin{
  margin: 0 !important;
}
.underline{
  text-decoration: underline;
}
.img-shadow{
  box-shadow: -20px 20px 40px -5px rgba(0,0,0,0.10);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.p-extra-margin-bottom{
  margin-bottom:48px;
}

// center vertically and/or horizontally an absolute positioned element
@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}